import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import styled from '@emotion/styled';

import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import dictionary from '../../utils/dictionary';
import { freespinNewPassword } from '../../utils/api/freespin';
import { clearNewPass } from '../../utils/api';

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  
  & h3 {
    width: 60%;
    text-align: center;
    margin: 0 auto;
    
    & a {
      &:hover {
        opacity: 0.8;
      }
      
      &:active {
        opacity: 0.6;
      }
    }
  }
`;

const ResetPassword = ({ data, pageContext, search }) => {
  const [processing, setProcessing] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const { wordpressPage: currentPage } = data;
  const { path } = currentPage;
  const metaTitle = currentPage.yoast_meta?.yoast_wpseo_title || `${currentPage.title}- ${process.env.SITE_NAME_SHORT}`;
  const { yoast_meta: { yoast_wpseo_metadesc: metaDesc } = { yoast_wpseo_metadesc: '' } } = currentPage;
  const { breadcrumbs } = pageContext;

  const location = useLocation();

  const { code, action } = location.search ? queryString.parse(location.search) : {};

  useEffect(() => {
    if (action !== 'reset') {
      setProcessing(false);
      setError(dictionary.actionNotValid);
      return () => {};
    }

    freespinNewPassword(code)
      .then((json) => {
        clearNewPass();

        if (json.error) {
          setError(json.message);
          setProcessing(false);
          return false;
        }

        setError('');
        setSuccess(dictionary.passwordWasChenged);
        setProcessing(false);
      })
      .catch((err) => {
        // console.log('New pass request: ', err);
        clearNewPass();
      });
  }, []);

  return (
    <Layout>
      <SEO title={metaTitle} description={metaDesc} pathname={path} breadcrumbs={breadcrumbs} />
      <Wrap>
        {
          processing
            ?
            <h1 align="center">{dictionary.processing}</h1>
            :
              <h1 align="center">{dictionary.processed}</h1>
        }
        {
          error && <h3>{error}</h3>
        }
        {
          success && (
            <>
              <h3>{success}</h3>
              <h3>
                <Link to="/">{dictionary.home}</Link>
              </h3>
              <h3>
                <Link to="/profile">{dictionary.profile}</Link>
              </h3>
            </>
          )
        }
      </Wrap>
    </Layout>
  );
};

export default ResetPassword;

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
  }
`;
